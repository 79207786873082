
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import InputCheckbox from "../../components/inputs/inputCheckbox/InputCheckbox.vue";
import InputCode from "../../components/inputs/inputCode/InputCode.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from "vue";
import { useAuthStore } from "../../store/auth";
import { storeToRefs } from "pinia";
import { getCookie } from "@/common/cookies";
import { useRoute, useRouter } from "vue-router";
import { useHelpersStore } from "@/store/helpers";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
export default defineComponent({
    name: "TwoFactor",
    components: {
        AuthContainer,
        CustomButton,
        InputCode,
        InputCheckbox,
        LoadingAnimation,
    },
    setup() {
        const { t } = useI18n();
        const { authCode, trustBrowser, authUser } = storeToRefs(
            useAuthStore()
        );
        const { loadingIds } = storeToRefs(useHelpersStore());
        const isDisabled = ref<boolean>(false);
        const { loginTwoFactor, login } = useAuthStore();
        const {
            setLoadingId,
            removeLoadingId,
            removeErrorMessage,
            setToastMessage,
        } = useHelpersStore();
        const router = useRouter();

        watch(
            () => authCode.value?.code,
            async () => {
                if (authCode.value?.code?.length == 5) {
                    isDisabled.value = true;
                    setLoadingId("two-factor-cancel");
                    await loginTwoFactor(authCode.value);
                    removeLoadingId("two-factor-cancel");
                    isDisabled.value = false;
                }
            }
        );
        async function userLogin(): Promise<void> {
            setLoadingId("logging-in");
            const response = await login();
            removeLoadingId("logging-in");
            if (response?.status == 423) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("resend_two_fa_succeeded")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("resend_two_fa_failed")
                );
            }
        }

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (loadingId) => loadingId.id == "logging-in"
            );
        });

        const route = useRoute();
        const cancelRoute = computed(() => {
            let routeName = "AuthLogin";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthLogin";
            }
            return routeName;
        });

        onMounted(() => {
            let routeName = "AuthLogin";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthLogin";
            }
            if (
                getCookie("logged_in") ||
                (!authUser.value.email && !authUser.value.password)
            ) {
                router.push({ name: routeName });
            }
            removeErrorMessage("code");
        });

        onUnmounted(() => {
            removeErrorMessage("email");
            removeErrorMessage("password");
        });

        return {
            trustBrowser,
            authCode,
            isDisabled,
            userLogin,
            cancelRoute,
            isLoading,
        };
    },
});
