
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  ref,
} from "vue";
import MetaHandler from "../metaHandler/MetaHandler.vue";
import { InputCodeStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import { inputCodeDefaultStyle } from "./input_code_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { MetaInformationInterface } from "@/store/helpers/interfaces";

export default defineComponent({
  name: "InputCode",
  components: { MetaHandler },
  props: {
    /**
     * id is necessary to uniquely identify this input
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * specifies the name of the HTML Input element
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * the displayed value of the input
     */
    modelValue: {
      required: true,
    },
    /**
     * if this is set the input saves space for the bottom meta container and the message can get displayed
     */
    hasMeta: {
      type: Boolean,
      default: true,
    },
    /**
     * the length of the given code
     */
    length: {
      type: [String, Number],
      default: 6,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Configuration Object for Meta Messages with
     * following attributes:
     * {
     *      ! errorMessage?: string;
     *      ! infoMessage?: string;
     *      ! saveMessage?: string;
     * }
     */
    inputMeta: {
      type: Object as () => InputMetaInterface,
      default: {} as InputMetaInterface,
    },

    /**
     * Configuration Object for Input Style with
     * following attributes:
     * {
     *      ! borderColor?: string;
     *      ! hoverBorderColor?: string;
     *      ! focusBorderColor?: string;
     *      ! activeBorderColor?: string;
     *      ! textColor?: string;
     *      ! backgroundColor?: string;
     * }
     */
    inputStyle: {
      type: Object as () => InputCodeStyleInterface,
      default: {} as InputCodeStyleInterface,
    },
    /**
     * tabindex for the input
     */
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const computedCodeInputStyle: ComputedRef<InputCodeStyleInterface> =
      computed(() => {
        return {
          ...inputCodeDefaultStyle,
          ...getSpaceConfigStyle()?.inputCode,
          ...props.inputStyle,
        };
      });
    /**
     * variable that stores the numbers of the code
     */
    const codeValue = ref<Array<string>>([]);

    const codeInputs = ref<HTMLInputElement[]>([]);

    const { errorMessages } = storeToRefs(useHelpersStore());
    const { removeErrorMessage } = useHelpersStore();
    const inputMessage = computed(() => {
      const foundError = errorMessages.value?.find(
        (element: any) => element.id == props.id
      );
      return foundError;
    });

    const metaInformation = computed(() => {
      let metaInfos: MetaInformationInterface = {};
      errorMessages.value.forEach((error: MetaInformationInterface) => {
        if (error.name == props.id && error.value) {
          metaInfos.errorMessage = error.value;
        }
      });
      if (
        !metaInfos.errorMessage &&
        !metaInfos.infoMessage &&
        !metaInfos.saveMessage
      ) {
        return null;
      } else {
        return metaInfos;
      }
    });

    function setInputRef(el: HTMLInputElement): void {
      if (el) {
        codeInputs.value.push(el);
      }
    }

    onBeforeUpdate(() => {
      codeInputs.value = [];
    });
    /**
     * handles input event and creates a string with each given input
     */
    function handleInputEvent(e: Event, index: number): void {
      let value = createString();
      removeErrorMessage(props.id);
      if (
        codeValue.value[index - 1] != null &&
        codeValue.value[index - 1] != ""
      ) {
        let nextElement = codeInputs.value[index];
        nextElement?.focus();
      } else {
        let nextElement = codeInputs.value[index - 2];
        nextElement?.focus();
        value = createString();
      }
      ctx.emit("update:modelValue", value);
    }
    /**
     * creates a string with the given inputs
     */
    function createString(): string {
      let value = "";
      codeValue.value.forEach((code) => {
        value = value + code;
      });
      return value;
    }
    /**
     * paste handler which enables pasting into the input
     */
    function handlePaste(event: ClipboardEvent): void {
      const { clipboardData } = event;
      const propLength =
        typeof props.length == "string" ? parseInt(props.length) : props.length;
      const pastedText: string | undefined = clipboardData?.getData("text");
      if (pastedText == undefined) {
        return;
      }
      for (let index = 0; index < propLength; index++) {
        codeValue.value[index] = pastedText.charAt(index);
      }
      let nextElement =
        codeInputs.value[
          pastedText.length < props.length ? pastedText.length : propLength - 2
        ];

      nextElement?.focus();
    }
    /**
     * mounted adding paste event to each input box
     */
    onMounted(() => {
      const propLength =
        typeof props.length == "string" ? parseInt(props.length) : props.length;
      for (let index = 1; index <= propLength; index++) {
        document
          .getElementById("codeInput" + index)
          ?.addEventListener("paste", handlePaste);
      }
    });

    return {
      handleInputEvent,
      computedCodeInputStyle,
      codeValue,
      codeInputs,
      setInputRef,
      inputMessage,
      metaInformation,
    };
  },
});
