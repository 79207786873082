import { InputCodeStyleInterface } from "../input_interfaces";

export const inputCodeDefaultStyle: InputCodeStyleInterface = {
    borderColor: "grey",
    hoverBorderColor: "primary",
    focusBorderColor: "primary",
    activeBorderColor: "primary",
    textColor: "black",
    backgroundColor: "transparent",
    heightClasses: "h-16",
    widthClasses: "w-14",
    textSize: "2xl",
};
