import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "text-center text-sm text-grey-darker mt-7" }
const _hoisted_3 = {
  key: 0,
  class: "absolute right-20 top-14 -mt-px"
}
const _hoisted_4 = { class: "w-full ml-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_input_code = _resolveComponent("input-code")!
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_auth_container = _resolveComponent("auth-container")!

  return (_openBlock(), _createBlock(_component_auth_container, null, {
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-full flex flex-col justify-start items-start gap-y-4 overflow-auto scrollbar pr-1" }, null, -1)),
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t("auth.two_factor")), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.two_factor_text")) + " ", 1),
          _createElementVNode("a", {
            class: "text-primary cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.userLogin()))
          }, _toDisplayString(_ctx.$t("auth.resend_code")), 1)
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_loading_animation, {
                id: 'load-2fa',
                name: 'load-2fa',
                animationStyle: { radius: '7', strokeLength: '5' }
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.authCode)
        ? (_openBlock(), _createBlock(_component_input_code, {
            key: 0,
            id: 'code',
            name: 'code',
            modelValue: _ctx.authCode.code,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.authCode.code) = $event)),
            length: 5,
            class: "mt-7 mb-5",
            isDisabled: _ctx.isDisabled
          }, null, 8, ["modelValue", "isDisabled"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_input_checkbox, {
          modelValue: _ctx.trustBrowser,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trustBrowser) = $event)),
          id: "checkbox",
          name: "checkbox",
          label: _ctx.$t('auth.trust_browser')
        }, null, 8, ["modelValue", "label"])
      ]),
      _createVNode(_component_custom_button, {
        id: 'two-factor-cancel',
        class: "w-full mt-5",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: _ctx.cancelRoute }))),
        isPlainButton: true
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.cancel")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}